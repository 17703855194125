import React, {useContext, useState} from "react"
import PropTypes from "prop-types"
import Link from "next/link"
import {useTranslation} from "next-i18next"
import {useRouter} from "next/router"
import styles from "../navigation.module.scss"
import FilterContext from "../../../context/FilterContext"
import urlParams from "../../../utility/UrlParams"
import {filterUrl} from "../../filter/FilterUrl"
import Typography from "../../reusable/Typography/Typography"
import {CollectionName} from "../../../constants"
import {gaCategories, gaEvents} from "../../../config/googleAnalytics/events"
import AnalyticsContext from "../../../context/AnalyticsContext"
import LayoutContext from "../../../context/LayoutContext"

type Props = {
  label: string
  href?: string
  classGroup?: string,
  className?: string,
  langSwitcher?: boolean,
  action?: () => void
  sortList?: boolean
  collectionName?: CollectionName
  customUrlBaseValue?: string
  onClick?: () => void
  sortValue?: string
}

const NavDropdownItem = ({
	onClick,
	sortList,
	label,
	href,
	classGroup,
	className,
	langSwitcher,
	collectionName,
	action,
	customUrlBaseValue,
	sortValue
}: Props): React.ReactElement => {
	const {t} = useTranslation("common")
	const router = useRouter()
	const params = router.query
	const {reactGA} = useContext(AnalyticsContext)
	const {isMobile} = useContext(LayoutContext)

	const {setFiltersLoading, categoryOrBrandName} = useContext(FilterContext)
	const sortOrder = params.sortOrder === "-" || params.sortOrder === "neuestes" ? t("neuestes") : params.sortOrder

	const [order, setOrder] = useState(sortOrder)
	return (
		<>
			{langSwitcher ?
				<li
					onClick={() => action()}
					className={`col-12 ${styles.navDropdownItem} ${router.asPath === href ? styles.navDropdownItemActive : ""} ${className ? className : ""}`}>
					<span className={`text-dark text-decoration-none ${classGroup}`}>
						{t(label)}
					</span>
				</li> :
				sortList ?
					<div className="px-0">
						<li
							className={`col-12 px-0 cursor-pointer ${styles.navDropdownItem} ${className ? className : ""}`}
							onClick={() => {
								setFiltersLoading(true)
								const url = filterUrl(params, urlParams.sortOrder, href, t, collectionName, categoryOrBrandName, customUrlBaseValue)
								reactGA.event({
									category: gaCategories.plp,
									action: gaEvents.sortOut,
									label,
									nonInteraction: false
								})
								router.push(url, undefined, {shallow: true, scroll: false}).then(() => {
									setFiltersLoading(false)
								})
							}}>
							<label>
								<input
									className="d-none"
									type="radio"
									name="sortOrder"
									value={sortOrder}
									checked={params.sortOrder === sortOrder && label.toLowerCase() === sortValue?.toLowerCase()}
									onChange={event => {
										setOrder(event.target.value)
									}}
								/>
								<Typography variant={isMobile ? "bodySm" : "bodyLg"} semanticTag="span" className={`ps-2 cursor-pointer ${classGroup}`}>
									{(urlParams.sortOrder === t("aufsteigend") || urlParams.sortOrder === "-") ? t("Neuestes") : t(label)}
								</Typography>
							</label>
						</li>
					</div> :
					<li
						className={`col-12 ${styles.navDropdownItem} ${router.asPath === href ? styles.navDropdownItemActive : ""} ${className ? className : ""}`}>
						<Link href={href}
							as={t(href)}>
							<a className={`text-dark text-decoration-none ${classGroup}`}>{t(label)}</a>
						</Link>
					</li>}
		</>
	)
}

NavDropdownItem.propTypes = {
	label: PropTypes.string.isRequired,
	href: PropTypes.string,
	classGroup: PropTypes.string,
	className: PropTypes.string,
	align: PropTypes.string
}

export default NavDropdownItem
