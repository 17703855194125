import React from "react"
import Link from "next/link"
import {useTranslation} from "next-i18next"
import Typography from "../reusable/Typography/Typography"
import styles from "./footerStyles.module.scss"

const FooterLabel = () => {
	const {t} = useTranslation()

	return (
		<section className="row pb-md-3">
			<div className="col-12 col-lg-7 footer-down order-1 order-md-0 mt-4 mt-md-0">
				<Typography variant="bodyLg" semanticTag="p" className={`${styles.footerLabel} mb-4 mb-md-0`}
				>© MyHomePlan.ch</Typography>
			</div>
			<div className="col-12 col-lg-5 footer-down row mb-4 mb-md-0">
				<Link href={t("/terms-conditions")}>
					<a className="col-auto mb-4 mb-md-0">
						<Typography className={`${styles.footerLabel}`} variant="bodyLg" semanticTag="span"
						>{t("Allgemeine Geschäftsbedingungen")}</Typography>
					</a>
				</Link>
				<Link href={t("/impressum")}>
					<a className="col-12 col-md-auto"><Typography className={`${styles.footerLabel}`} variant="bodyLg" semanticTag="span"
					>{t("Impressum")}</Typography></a>
				</Link>
			</div>
		</section>
	)
}

export default FooterLabel
