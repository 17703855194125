import {useFormikContext} from "formik"
import React from "react"
import {getCountryData} from "../../../utility/CountryPhoneData"
import NavDropdownItem from "../../navigation/desktop/NavDropdownItem"
import Dropdown from "../../reusable/components/Dropdown/Dropdown"

const PhoneDropDown:React.FC<any> = ({countries, classGroup, isOpen, setIsOpen}) => {
	const {setFieldValue, values, touched} = useFormikContext()

	const align = "start"

	const handleCountrySelection = (country:string):void => {
		setIsOpen(false)
		setFieldValue("prefix", country.toUpperCase())
	}

	const height = countries.length < 3 ? "auto" : 160
	const width = 70

	return (
		<li style={{zIndex: 12000}}
			className={`position-relative pointer list-unstyled col-auto p-0 ${align === "start" ? "me-4" : "ms-4"} text-bold}`}>
			{/* <span>
				<Typography variant="bodyLgBold" semanticTag="span"
					className={`text-uppercase ${classGroup}`}></Typography>
				<CaretDownFill className="ms-2 mb-1"/>
			</span> */}
			<Dropdown isOpen={isOpen} height={height} width={width} top={-28} left={12}>
				<ul className={`bg-white border px-2 py-0 row list-unstyled overflow-auto ${countries.length === 0 ? "d-none" : ""}`} style={{
					height
				}}>
					{countries.map(country => {
						return <NavDropdownItem key={country.name} label={`${country.code} (${country.dial_code})`}
							action={() => handleCountrySelection(`${country.code} (${country.dial_code})`)} langSwitcher/>
					})}

				</ul>
			</Dropdown>
		</li>
	)
}

const filterCountries = (prefixVal:string):any[] => {
	const countries = getCountryData()
	const filteredCountries = prefixVal.length === 1 ?
		countries.filter(country => country.code.slice(0, 1) === prefixVal || country.code.slice(0, 1).toLowerCase() === prefixVal.toLowerCase() || country.dial_code.slice(0, 1).toLowerCase() === prefixVal.toLowerCase()	|| country.dial_code.slice(1, 2).toLowerCase() === prefixVal.toLowerCase()) :
		prefixVal.length === 2 ? countries.filter(country => country.code.slice(0, 1) === prefixVal || country.code.slice(0, 2).toLowerCase() === prefixVal.toLowerCase() || country.dial_code.slice(0, 2).toLowerCase() === prefixVal.toLowerCase() || country.dial_code.slice(1, 3).toLowerCase() === prefixVal.toLowerCase()) :
			prefixVal.length === 3 ? countries.filter(country => country.code === prefixVal || country.code.toLowerCase() === prefixVal.toLowerCase() || country.dial_code.toLowerCase() === prefixVal.toLowerCase()) :
				prefixVal.length > 0 ? countries : countries

	return filteredCountries
}

export default PhoneDropDown
