import React from "react"
import PropTypes from "prop-types"
import Head from "next/head"

type Props = {
    children: React.ReactNode
}
const MbpHead: React.FC<Props> = ({children}) => {
	const fbEndPoint = `https://www.facebook.com/tr?id=${process.env.NEXT_PUBLIC_FB_PIXEL_ID}&ev=PageView&noscript=1`
	return (
		<Head>
			{children}
			<link rel="icon" href="/favicon.png"/>
			<script
				data-partytown-config
				dangerouslySetInnerHTML={{
					__html: `function hotjar(h, o, t, j, a, r) {
	h.hj = h.hj || function () {
		// eslint-disable-next-line prefer-rest-params
		(h.hj.q = h.hj.q || []).push(arguments)
	}

	h._hjSettings = {hjid: 3618315, hjsv: 6}
	a = o.getElementsByTagName("head")[0]
	r = o.createElement("script")
	r.async = 1
	r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv
	a.appendChild(r)
}

hotjar(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=")`
				}}/>
			<script type="text/javascript" id="hs-script-loader" src="//js.hs-scripts.com/6857669.js"/>
			<script dangerouslySetInnerHTML={{
				__html: `<!-- Google Tag Manager -->
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_ENVIRONMENT === "production" ? process.env.NEXT_PUBLIC_GTM : "GTM-KL4PZK3"}');
<!-- End Google Tag Manager -->`
			}}/>
			<noscript>
				<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-N6R73LDR"
					height="0" width="0" style={{
						display: "none",
						visibility: "hidden"
					}}>
				</iframe>
			</noscript>
			<meta name="google-site-verification" content="NoqcQKyJtugIf97PNCa2RYYNx3ENK26zLpIpGZAzP_Y"/>
			<meta name="p:domain_verify" content="d8a17453ff9a1971337c39f3f3720115"/>
			<script dangerouslySetInnerHTML={{__html: `!function(f,b,e,v,n,t,s)
				  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
				  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
				  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
				  n.queue=[];t=b.createElement(e);t.async=!0;
				  t.src=v;s=b.getElementsByTagName(e)[0];
				  s.parentNode.insertBefore(t,s)}(window, document,'script',
				  'https://connect.facebook.net/en_US/fbevents.js');
				  fbq('init', ${process.env.NEXT_PUBLIC_FB_PIXEL_ID});
				  fbq('track', 'PageView');`}}
			/>
			<script dangerouslySetInnerHTML={{__html: `window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
  heap.load("1280289469");`}}
			/>
			<noscript dangerouslySetInnerHTML={{__html: `<img height="1" width="1" style="display:none"
      			src=${fbEndPoint} />`}}
			/>
		</Head>
	)
}

MbpHead.propTypes = {
	children: PropTypes.node.isRequired
}

export default MbpHead
