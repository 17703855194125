/* eslint-disable dot-notation */
import {useFormikContext} from "formik"
import {useTranslation} from "next-i18next"
import React, {useState} from "react"
import {getCountryData} from "../../../utility/CountryPhoneData"
import {phonePrefixSearch} from "../../../utility/Helper"
import FormInput from "../../reusable/FormInput/FormInput"
import PhoneDropDown from "./PhoneDropDown"

interface Props {
	classNameContainer?: string
}

const PhoneField:React.FC<Props> = ({classNameContainer}) => {
	const {setFieldValue, errors, touched, values} = useFormikContext()
	const {t} = useTranslation()
	const [isOpen, setIsOpen] = useState(false)

	const countriesList = getCountryData()
	const countries = phonePrefixSearch(values["prefix"], countriesList)

	const handleBlur = ():void => {
		if (countries.length === 0) {
			setFieldValue("prefix", "CH (+41)")
		} else {
			setFieldValue("prefix", countries[0].label)
		}

		setIsOpen(false)
	}

	return (
		<div className={classNameContainer ? classNameContainer : "col-12 col-lg-5"}>
			<div className="row">
				<div className="col-4">
					<FormInput
						isNewDesign
						onKeyDown={() => setIsOpen(true)}
						onBlur={handleBlur}
						onFocus={() => setIsOpen(true)}
						className="pb-3" errors={errors}
						touched={touched}
						name="prefix"
						required
						errColor="dark" label={t("Prefix")}/>
					<PhoneDropDown countries={countries} isOpen={isOpen} setIsOpen={setIsOpen} />
				</div>
				<div className="col-8">
					<FormInput
						isNewDesign
						className="pb-3 data-cypress-personal-data-phone-number"
						errors={errors}
						touched={touched}
						name="phone"
						required
						type={"number"}
						// Disabled={countryCode}
						errColor="dark" label={t("Telefon")}/>
				</div>
			</div>
		</div>
	)
}

export default PhoneField

// OnFocus={() => setIsOpen(true)} onBlur={() => setIsOpen(true)}
