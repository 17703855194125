import React from "react"

export type FilterContextState = {
	filtersLoading: boolean,
	// eslint-disable-next-line no-unused-vars
	setFiltersLoading: (isLoading:boolean) => void,
	customUrlBase: string | null,
	isCategoryPage: boolean | null,
	isBrandPage: boolean | null,
	categoryOrBrandName: string | null,
	customProductsList: any[] | null,
	setCustomProductsList: (products:any[])=>void
}

const FilterContext = React.createContext<FilterContextState>({
	filtersLoading: false,
	setFiltersLoading: () => {
		// Do nothing
	},
	customUrlBase: null,
	isCategoryPage: null,
	isBrandPage: null,
	categoryOrBrandName: null,
	customProductsList: null,
	setCustomProductsList: () => {
		// Do nothing
	}
})

export default FilterContext
