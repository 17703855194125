import React from "react"
import AnimationComponent from "../AnimationComponent/AnimationComponent"

type Props = {
	isOpen: boolean;
	closeDropdown?: () => void;
	children: React.ReactNode;
	top?: number;
	left?: number;
	backdrop?: boolean;
	sortList?: boolean;
	height?: number | string;
	width?: number | string;
	className?: string;
	style?: any
};

const Dropdown = ({
	className,
	height,
	width,
	sortList,
	isOpen,
	closeDropdown = () => {},
	backdrop,
	top = 0,
	left = 0,
	children,
	style
}: Props) => {
	return (
		<AnimationComponent inProp={isOpen} type="fade">
			<>
				{backdrop && (
					<div
						className="position-fixed vh-100 vw-100"
						style={{
							backgroundColor: "#0000000",
							zIndex: 10000,
							top: 0,
							left: 0
						}}
						onClick={closeDropdown}
					/>
				)}
				{sortList ? (
					<div
						style={{zIndex: 11000, top, left, height, width, ...style}}
						className={`${className} position-absolute p-0 pt-1 d-block  container`}
					>
						{children}
					</div>
				) : (
					<div
						style={{zIndex: 11000, top, left, height, width, ...style}}
						className={`${className} position-absolute p-0 pt-1 d-block shadow container`}
					>
						{children}
					</div>
				)}
			</>
		</AnimationComponent>
	)
}

export default Dropdown
