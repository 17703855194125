import {useFormikContext} from "formik"
import React, {useEffect, useState} from "react"
import {ChevronDown, ChevronUp} from "react-bootstrap-icons"
import Typography from "../Typography/Typography"

type Field = {
    name: string
    value: string | number
}
type Form = {
    errors: {}
    touched: {}
    setFieldValue: (name: string | number, value: string | number) => void
}
type Option = {
    label: string
    value: string | number
}
type Props = {
    field: Field
    form: Form
    label: string
    options: Option[]
    required?: boolean
    t: (text) => string
    outLabel?: boolean
	placeholder?: string
}

const FormSelect = ({
	outLabel,
	field: {name, value},
	form: {errors, touched, setFieldValue},
	label,
	options,
	required,
	placeholder,
	t
}: Props): React.ReactElement => {
	const [isOpen, setIsOpen] = useState(false)
	const [activeOptionLabel, setActiveOptionLabel] = useState<string>(placeholder ? placeholder : options[0].label)
	const {values} = useFormikContext()

	const handleOptionClick = (e, optionValue) => {
		e.stopPropagation()
		const optionLabel = options.find(option => option.value === optionValue).label
		setFieldValue(name, optionValue)
		setActiveOptionLabel(optionLabel)
		setIsOpen(false)
	}

	useEffect(() => {
		const optionSelectedLabel = options.find(option => option.value === values[name])?.label
		if (optionSelectedLabel) {
			setActiveOptionLabel(optionSelectedLabel)
		}
	}, [])

	return (
		<div className="pointer">
			{outLabel ? (
				<label htmlFor={name}
					className="position-relative bg-white text-bold text-dark text-nowrap text-start d-block d-md-block text-truncate">
					{<Typography variant="bodySmBoldMdBodyLgBold" semanticTag="span"
						className="text-truncate d-inline-block w-auto overflow-hidden">{t(label)}</Typography>}
					{required && <span style={{display: "inline-block", transform: "translateY(-8px)"}} className="text-danger">*</span>}
				</label>
			) : null}
			<div
				onClick={() => {
					setIsOpen(prevState => !prevState)
				}} className="position-relative border-bold rounded mb-4 pt-3 pb-3 px-2">
				{outLabel ?
					<label htmlFor={name}
						style={{
							marginTop: "-25px"
						}}
						className="position-absolute mx-1 px-1 bg-white text-bold text-dark text-nowrap text-center d-none d-md-none">
						{<Typography variant="bodyLg" semanticTag="span"
							className="text-truncate d-inline-block w-auto overflow-hidden">{t(label)}</Typography>}
						{required && <span style={{display: "inline-block", transform: "translateY(-8px)"}} className="text-danger">*</span>}
					</label> : (
						<label style={{
							marginTop: "-25px"
						}} htmlFor={name}
						className="position-absolute px-2 bg-white text-bold text-dark text-nowrap text-center">
							{<Typography variant="bodyLg" semanticTag="span"
								className="text-truncate d-inline-block w-auto overflow-hidden">{t(label)}</Typography>}
							{required && <span style={{display: "inline-block", transform: "translateY(-8px)"}} className="text-danger">*</span>}
						</label>
					)}
				<div className="position-relative d-flex  justify-content-between">
					<div>{t(activeOptionLabel)}</div>
					<div className="">{isOpen ? <ChevronUp size={14}/> : <ChevronDown size={14}/>}</div>
					<div style={{zIndex: 1000, top: 42, left: -10, right: -10, maxHeight: 350}}
						className={`pb-2 bg-light position-absolute shadow border-0 rounded-1 border-2 scrollbar-hide ${isOpen ? "" : "d-none"}`}
						aria-labelledby="dropdownMenuButton1">
						{options.map((option, id) =>
							<div
								key={option.label + id}
								onClick={e => handleOptionClick(e, option.value)}
								className={"p-2 pb-0"}>
								<span
									className={`d-grid rounded ${option.value === value ? "border border-primary bg-primary-light" : "bg-white"}`}>
									<button type={"button"} className={"btn"}>
										<div className={"row g-0 justify-content-between"}>
											<Typography variant="bodyLg" semanticTag="div" className={"col-auto"}>
												{t(option.label)}
											</Typography>
										</div>
									</button>
								</span>
							</div>
						)}
					</div>
				</div>
				{errors[name] && touched[name] ? (
					<div style={{color: "red"}}>{errors[name]}</div>
				) : null}
			</div>
		</div>
	)
}

export default FormSelect
