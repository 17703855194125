import React, {useEffect} from "react"
import {Field, useField, useFormikContext} from "formik"
import {dateFormaterFromMobile} from "../../../utility/dateFormater"
import FormInput from "../../reusable/FormInput/FormInput"
import {useTranslation} from "next-i18next"
import FormSelect from "../../reusable/FormSelect/FormSelect"

const DatePickerField: React.FC<any> = ({label, noMaxDate = false, ...props}) => {
	const {setFieldValue, values} = useFormikContext()
	const [field] = useField(props)
	const {errors, touched} = useFormikContext<any>()

	return (
		<div className={`d-md-none position-relative border-bold rounded mb-3 pt-3 pb-5 ${errors[field.name] && touched[field.name] && "border-danger"}`}>
			<label
				className={"position-absolute px-2 text-center text-bold bg-white text-dark"}
				style={{
					marginTop: "-25px"
				}}
			>{label}<span className="text-danger">*</span></label>
			<div className="position-absolute w-100" style={{
				zIndex: 0
			}}>
				{dateFormaterFromMobile(values[field.name])}
			</div>
			<input
				// As={CustomDateInputComponent}
				onChange={e => {
					setFieldValue(field.name, e.target.value)
				}}
				name={field.name}
				style={{
					color: "transparent",
					zIndex: 1000,
					height: "100%"
				}}
				className={"transparent-input position-absolute w-100"}
				type="date"
				placeholder={"mm/dd/yyyy"}
				value={values[field.name]}
				// Min={noMaxDate ? dateFormater(new Date(), 2) : null}
				// max={noMaxDate ? null : dateFormater(new Date(), 2)}
			/>
		</div>
	)
}

export default DatePickerField

export const DatepickerInputs:React.FC<any> = ({name, label}) => {
	const {t} = useTranslation("common")
	const {errors, touched, values, setFieldValue} = useFormikContext<any>()
	const reg = /^\d+$/
	const regex = new RegExp(reg)

	useEffect(() => {
		setFieldValue(`${name}_day`, values[`${name}_day`] > 31 ? 31 : values[`${name}_day`])
		setFieldValue(`${name}_year`, values[`${name}_year`] > 3000 ? 2022 : values[`${name}_year`])

		if (values[`${name}_day`] && values[`${name}_month`] && values[`${name}_year`] &&
			values[`${name}_day`] !== "" && values[`${name}_month`] !== "" && values[`${name}_year`] !== "") {
			setFieldValue(name, `${values[`${name}_day`] && values[`${name}_day`] < 10 ? "0" + values[`${name}_day`] : values[`${name}_day`]}/${values[`${name}_month`] && values[`${name}_month`]}/${values[`${name}_year`] && values[`${name}_year`]}`)
		}
	}, [values[`${name}_day`], values[`${name}_month`], values[`${name}_year`]])

	return (<>
		<div>
			<label
				className={"mb-3 mt-3 text-center text-bold bg-white text-dark"}
				style={{
					marginTop: "-25px"
				}}
			>{label}<span className="text-danger">*</span></label>
		</div>
		<div className="d-flex align-items-center">
			<div className="col-3">
				<FormInput
					isNewDesign
					required
					label={t("Day")}
					name={`${name}_day`}
					type={"number"}
					placeholder={"dd"}
					className={"pb-3 data-cypress-personal-data-bday"}
					errors={errors}
					touched={touched}
					min="1"
					max="31"
					errorDisplay={false}
					onChange={(e:any) => {
						if (regex.test(e.target.value)) {
							setFieldValue(`${name}_day`, (e.target.value).trim())
						}
					}}
				/>
			</div>

			<div className="col-4 mx-2">
				<Field className="data-cypress-personal-data-bmonth" outLabel name={`${name}_month`} label="Month" required
					component={FormSelect} t={t} options={monthOptions}/>
			</div>

			<div className="col-4">
				<FormInput
					isNewDesign
					required
					label={t("Year")}
					name={`${name}_year`}
					type={"number"}
					placeholder={"YYYY"}
					className={"pb-3 data-cypress-personal-data-byear"}
					errors={errors}
					touched={touched}
					errorDisplay={false}
					onChange={(e:any) => {
						if (regex.test(e.target.value)) {
							setFieldValue(`${name}_year`, (e.target.value).trim())
						}
					}}
				/>
			</div>
		</div>
	</>
	)
}

const monthOptions = [
	{
		label: "January",
		value: "01"
	},
	{
		label: "February",
		value: "02"
	},
	{
		label: "March",
		value: "03"
	},
	{
		label: "April",
		value: "04"
	},
	{
		label: "May",
		value: "05"
	},
	{
		label: "June",
		value: "06"
	},
	{
		label: "July",
		value: "07"
	},
	{
		label: "August",
		value: "08"
	},
	{
		label: "September",
		value: "09"
	},
	{
		label: "October",
		value: "10"
	},
	{
		label: "November",
		value: "11"
	},
	{
		label: "December",
		value: "12"
	}
]

